import { useGlobalStateValue } from 'store/store';
import clsx from 'clsx';
import { makeStyles, Theme, Drawer } from '@material-ui/core';
import NotificationsList from './NotificationsList/NotificationsList';

const useStyles = makeStyles((theme: Theme) => ({
  drawerOpen: {
    position: 'static',
    width: 350,
    height: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
  },
}));

const Notification: React.FC = () => {
  const {
    state: {
      appState: {
        drawers: { notificationDrawer },
      },
    },
  } = useGlobalStateValue();
  const { drawerOpen, drawerClose } = useStyles();

  return (
    <Drawer
      className={clsx({
        [drawerOpen]: notificationDrawer,
        [drawerClose]: !notificationDrawer,
      })}
      classes={{
        paper: clsx({
          [drawerOpen]: notificationDrawer,
          [drawerClose]: !notificationDrawer,
        }),
      }}
      open={notificationDrawer}
      anchor="right"
      variant="persistent"
    >
      <NotificationsList />
    </Drawer>
  );
};

export default Notification;
