import { FC, Dispatch, useMemo } from 'react';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import MainDrawerMenu from 'components/MainDrawerMenu/MainDrawer';
import RouteList from 'router/RouteList';
import privateRoutes from 'router/privateRoutes';
import { useQuery } from '@apollo/client';
import { USER_MAIN } from 'operations/queries/user';
import PageLoader from 'components/Loader/PageLoader';
import NotificationComponent from 'components/Notification/Notification';
import { mainQueryInitialState } from 'store/initialState';
import getPermissions from 'utils/getPermissions';
import Notification from './NavNotification/Notification';
import Nav from './Nav/Nav';
import Collaboration from './Collaboration/Collaboration';
import { mapConversations } from './Collaboration/collaboration-service';

const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.background.default,
  },
  container: {
    minHeight: 'calc(100% - 180px)',
  },
  noOverFlowX: {
    overflowX: 'hidden',
  },
  content: {
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    minHeight: '100%',
  },
}));

interface Props {
  userId: string;
  dispatch: Dispatch<any>;
  activeChatId: string;
}

const Main: FC<Props> = ({ userId, dispatch, activeChatId }) => {
  const { mainContainer, content, container, noOverFlowX } = useStyles();
  const history = useHistory();

  const { data, loading, error, subscribeToMore } = useQuery(USER_MAIN, {
    variables: { id: userId },
    onCompleted: async ({ getUser }) => {
      const { conversations, notifications, transactions, ...rest } = getUser;
      const { enabled, onboardingStatus } = getUser;
      if (!enabled) {
        dispatch({
          type: 'OPEN_SNACKBAR',
          payload: {
            snackbarMessage: 'Your user account is disabled by your admin',
            snackbarType: 'error',
          },
        });
        dispatch({
          type: 'UPDATE_AUTH',
          payload: false,
        });
        history.replace('/login');
      } else if (onboardingStatus === 'COMPLETED') {
        const permissions = await getPermissions();
        dispatch({
          type: 'UPDATE_USER_INFO',
          payload: {
            ...rest,
            permissions,
          },
        });
      } else {
        dispatch({
          type: 'UPDATE_AUTH',
          payload: false,
        });
        history.replace('/login');
      }
    },
    skip: !userId,
  });

  const userData = data?.getUser || mainQueryInitialState;
  const { unreadConversationsCount, result: chats } = useMemo(
    () => mapConversations(userData.conversations?.items || [], userId, activeChatId),
    [userData.conversations, activeChatId, userId]
  );

  if (loading) return <PageLoader />;
  if (error) return <NotificationComponent />;

  return (
    <Grid container wrap="nowrap" className={mainContainer}>
      <Grid item>
        <MainDrawerMenu />
      </Grid>
      <Grid container direction="column" wrap="nowrap" className={noOverFlowX}>
        <Nav notificationsCount={userData.notifications.items.length} messagesCount={unreadConversationsCount} />
        <Grid container className={container}>
          <Grid item xs className={content}>
            <RouteList routes={privateRoutes} />
          </Grid>
          <Grid item>
            <Notification />
          </Grid>
          <Grid item>
            <Collaboration chats={chats} transactions={userData.transactions.items} subscribeToMore={subscribeToMore} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Main;
