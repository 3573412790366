import { Button, Theme, Grid, makeStyles, Typography } from '@material-ui/core';
import Avatar from 'components/Avatar/Avatar';

interface Props {
  name: string;
  onLeave: () => void;
  onCancel: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: 'calc(100vh - 242px)',
    padding: '40px 8px 16px 8px',
  },
  text: {
    marginTop: theme.spacing(3),
  },
}));

const LeaveConfirmation: React.FC<Props> = ({ name, onLeave, onCancel }) => {
  const { container, text } = useStyles();

  return (
    <Grid
      className={container}
      container
      justifyContent="space-between"
      alignItems="center"
      direction="column"
    >
      <Avatar size={200} imgKey="" />
      <Grid item xs>
        <Typography align="center" className={text}>
          All messages exchanged with {name} will be deleted.
        </Typography>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs>
          <Button
            fullWidth
            onClick={onCancel}
            variant="contained"
            color="primary"
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs>
          <Button
            fullWidth
            onClick={onLeave}
            variant="contained"
            color="secondary"
          >
            Delete
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LeaveConfirmation;
