import { MouseEvent } from 'react';
import {
  makeStyles,
  ListItem,
  ListItemAvatar,
  Typography,
  Badge,
  List,
  Box,
  Grid,
} from '@material-ui/core';
import Avatar from 'components/Avatar/Avatar';
import { determineDateTime, truncateText } from 'utils/helpers';
import clsx from 'clsx';

const useStyles = makeStyles(({ spacing, palette }) => ({
  listContainer: {
    height: '100%',
  },
  listSearch: {
    height: 'calc(100% - 168px)',
  },
  container: {
    background: palette.common.white,
    padding: '0 8px',
    overflow: 'auto',
  },
  TransactionSearch: {
    height: 'calc(100% - 128px)',
  },
  listItem: {
    backgroundColor: palette.common.white,
    margin: '8px auto',
    borderRadius: spacing(0.5),
    boxShadow:
      '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
  },
  emptyConversation: {
    padding: `${spacing(3)}px ${spacing(6)}`,
    marginTop: spacing(1),
  },
  unreadCounter: {
    right: spacing(1.25),
    marginTop: spacing(1.5),
  },
}));

interface Props {
  userId: string;
  list: ChatType[];
  onClick: (
    chatId: string,
    userConversationId: string
  ) => (event: MouseEvent<HTMLDivElement>) => void;
  isSearch: boolean;
}

const CollaborationListItems: React.FC<Props> = ({
  userId,
  list,
  onClick,
  isSearch,
}) => {
  const {
    listContainer,
    listItem,
    container,
    listSearch,
    unreadCounter,
    TransactionSearch,
    emptyConversation,
  } = useStyles();

  return (
    <Box
      className={clsx({
        [container]: true,
        [listContainer]: true,
        [listSearch]: isSearch,
        [TransactionSearch]: !isSearch,
      })}
    >
      {list.length === 0 ? (
        <Typography align="center" className={emptyConversation}>
          {isSearch
            ? 'No search result found.'
            : 'You don’t have any active conversations.'}
        </Typography>
      ) : (
        <List>
          {list.map(
            ({
              participants,
              id: chatId,
              messages,
              unreadCount,
              userConversationId,
            }: ChatType) => {
              const user = participants.find(
                ({ id }: BasicUser) => id !== userId
              );
              const lastMessage =
                messages[messages.length - 1]?.message ||
                'Start a Conversation';
              const messageDateTime = determineDateTime(messages[0]?.createdAt);

              return (
                <ListItem
                  button
                  classes={{ root: listItem }}
                  key={chatId}
                  onClick={onClick(chatId, userConversationId)}
                >
                  <ListItemAvatar>
                    <Avatar size={40} imgKey={user.avatar?.key} />
                  </ListItemAvatar>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Grid container direction="column">
                        <Typography variant="body1">{`${user.firstname} ${user.lastname}`}</Typography>
                        <Typography variant="body2">
                          {truncateText(lastMessage, 25)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                      >
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="div"
                        >
                          {messageDateTime}
                        </Typography>
                        <Badge
                          classes={{ root: unreadCounter }}
                          badgeContent={unreadCount}
                          color="secondary"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItem>
              );
            }
          )}
        </List>
      )}
    </Box>
  );
};

export default CollaborationListItems;
