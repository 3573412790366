const awsConfig: Record<string, string | undefined> = {
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_REGION,
  aws_user_pools_id: process.env.REACT_APP_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOLS_WEB_CLIENT_ID,
  aws_appsync_graphqlEndpoint:
    process.env.REACT_APP_APPSYNC_ENDPOINT_APPLICATION,
  aws_appsync_region: process.env.REACT_APP_AWS_REGION,
};

export default awsConfig;
