import { SnackbarTypes } from 'store/storeTypes';

export const OPEN_SNACKBAR = 'OPEN_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

export type SnackbarActionsTypes =
  | {
      type: typeof OPEN_SNACKBAR;
      payload: {
        snackbarMessage: string;
        snackbarType?: SnackbarTypes;
      };
    }
  | { type: typeof CLOSE_SNACKBAR };
