import { formatDistanceToNow } from 'date-fns';
import {
  List,
  ListItem,
  Box,
  Typography,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core';
import BLogo from 'assets/Logo/bondify.png';

// interface Props {
//   notifications: any[];
// }

const NotificationsList = () => (
  // TODO: use groupBy

  <Box marginTop="20px" padding="20px">
    <Box paddingBottom="32px">
      <Typography variant="subtitle1">Notifications</Typography>
    </Box>
    <List>
      <ListItem disableGutters button>
        <ListItemAvatar>
          <Avatar alt="bondify-logo" src={BLogo} />
        </ListItemAvatar>
        <ListItemText
          primary="Welcome To Bondify"
          secondary={formatDistanceToNow(new Date())}
        />
      </ListItem>
    </List>
  </Box>
);
export default NotificationsList;
