import { FC, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { flattenRoutes } from 'utils/helpers';

const RouteList: FC<ComponentRoutes> = ({ routes }) => {
  const flatFilteredRoutes = useMemo(() => flattenRoutes(routes), [routes]);

  return (
    <Switch>
      {flatFilteredRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          exact
          render={(routeProps) => (
            <route.component match={routeProps.match} history={routeProps.history} location={routeProps.location} />
          )}
        />
      ))}
    </Switch>
  );
};

export default RouteList;
