import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import Drawer from '@material-ui/core/Drawer';
import { useGlobalStateValue } from 'store/store';
import { USER_COMPANY_SEARCH } from 'operations/queries/messaging';
import {
  NEW_CONVERSATION,
  CREATE_NEW_MESSAGE,
  NEW_MESSAGE_SUBSCRIPTION,
} from 'operations/mutations/messaging';
import debounce from 'lodash.debounce';
import { makeStyles, Box } from '@material-ui/core';
import set from 'lodash.set';
import clsx from 'clsx';
import CreateCompanyChat from './CollaborationCreate/CreateCompanyChat';
import CollaborationRoom from './CollaborationRoom/CollaborationRoom';
import CollaborationList from './CollaborationList/CollaborationList';
import { mapUsers } from './collaboration-service';

const useStyles = makeStyles(({ transitions }) => ({
  drawerOpen: {
    position: 'static',
    width: 350,
    overflow: 'hidden',
    transition: transitions.create('width', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    position: 'static',
    transition: transitions.create('width', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
  },
}));

interface Props {
  transactions: Transaction[];
  chats: ChatType[];
  subscribeToMore: any;
}

const Collaboration: React.FC<Props> = ({ subscribeToMore, chats }) => {
  const {
    dispatch,
    state: {
      userInfo: { id: userId, company },
      conversation: { collaborationPanel },
      appState: {
        drawers: { collaborationDrawerOpen },
      },
    },
  } = useGlobalStateValue();
  const [subscribedIds, setSubscribedIds] = useState<String[]>([]);
  const [searchInput, setSearchInput] = useState('');
  const { avatar, companyName, id: companyId } = company || {};
  const classes = useStyles();
  const [getUsers, { data: searchData }] = useLazyQuery(USER_COMPANY_SEARCH);
  const [createMessage] = useMutation(CREATE_NEW_MESSAGE);
  const [createNewConversation, { loading: loadingChatRoom }] = useMutation(
    NEW_CONVERSATION,
    {
      onCompleted: ({
        startNewConversation: { conversationId, userConversationId },
      }) => {
        dispatch({
          type: 'SET_ACTIVE_USER_CONVERSATION_ID',
          payload: userConversationId,
        });
        dispatch({ type: 'SET_ACTIVE_CHAT', payload: conversationId });
        dispatch({ type: 'SET_CHAT_PANEL', payload: 'room' });
      },
    }
  );

  const onSearchChange = (value: string) => {
    if (!value) {
      setSearchInput('');
      return;
    }
    setSearchInput(value);
    if (companyId) {
      getUsers({ variables: { id: companyId } });
    }
  };

  const onMessageSend = (
    message: string,
    conversationId: string,
    senderId: string
  ) => {
    createMessage({
      variables: {
        input: {
          conversationId,
          message,
          conversationMessageAuthorId: senderId,
        },
      },
    });
  };

  useEffect(() => {
    const remainedSubscriptions = chats.filter(
      ({ id }) => !subscribedIds.includes(id)
    );
    setSubscribedIds((prevIds) => [
      ...prevIds,
      ...remainedSubscriptions.map(({ id }) => id),
    ]);

    remainedSubscriptions.forEach(({ id }) => {
      subscribeToMore({
        document: NEW_MESSAGE_SUBSCRIPTION,
        variables: { conversationId: id },
        updateQuery: (prev: any, { subscriptionData }: any) => {
          if (!subscriptionData.data) return prev;

          const nextState = { ...prev };
          const subscriptionMessage =
            subscriptionData.data.onCreateConversationMessage;
          if (prev.getUser.conversations) {
            const conversations = prev.getUser.conversations.items;
            const conversationIndex = conversations.findIndex(
              (item: { conversation: ChatType }) => item.conversation.id === id
            );
            const currentMessages =
              conversations[conversationIndex].conversation.messages.items;
            set(
              nextState,
              `getUser.conversations.items[${conversationIndex}].conversation.messages.items`,
              [...currentMessages, subscriptionMessage]
            );

            return nextState;
          }

          return prev;
        },
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chats]);

  const renderPanel = () => {
    switch (collaborationPanel) {
      case 'room':
        return <CollaborationRoom chats={chats} onSend={onMessageSend} />;
      case 'startCompanyChat':
        return (
          <CreateCompanyChat
            searchData={mapUsers(searchData, userId, searchInput)}
            onSearchChange={debounce(onSearchChange, 500)}
            contextData={{
              avatar: avatar?.key,
              name: 'Start conversation',
              label: companyName,
            }}
            onUserSelected={(participantId: string) => {
              if (loadingChatRoom) return;

              const conversationFound = chats.find(({ participants }) =>
                participants.every(
                  ({ id }: BasicUser) => id === participantId || id === userId
                )
              );

              if (!conversationFound || conversationFound.transaction) {
                createNewConversation({
                  variables: {
                    users: [participantId],
                  },
                });
              } else {
                dispatch({ type: 'SET_CHAT_PANEL', payload: 'room' });
                dispatch({
                  type: 'SET_ACTIVE_CHAT',
                  payload: conversationFound.id,
                });
              }
            }}
            loading={loadingChatRoom}
          />
        );
      default:
        return <CollaborationList chats={chats} />;
    }
  };

  return (
    <Drawer
      className={clsx({
        [classes.drawerOpen]: collaborationDrawerOpen,
        [classes.drawerClose]: !collaborationDrawerOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: collaborationDrawerOpen,
          [classes.drawerClose]: !collaborationDrawerOpen,
        }),
      }}
      open={collaborationDrawerOpen}
      anchor="right"
      variant="persistent"
    >
      <Box width={350} height="calc(100vh - 100px)">
        {renderPanel()}
      </Box>
    </Drawer>
  );
};

export default Collaboration;
