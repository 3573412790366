import { lazy } from 'react';
// company
const CompanyOverview = lazy(() => import('scenes/Company/CompanyOverview/CompanyOverview'));

const CompanyTeam = lazy(() => import('scenes/Company/CompanyTeam/CompanyTeam'));
const CompanyRequests = lazy(() => import('scenes/Company/CompanyRequests/CompanyRequests'));

const ProfilePersonal = lazy(() => import('scenes/Profile/ProfilePersonal/ProfilePersonal'));

const SelectFactoryDocument = lazy(() => import('scenes/DocumentFactory/SelectDocument'));
const SelectFactoryMethod = lazy(() => import('scenes/DocumentFactory/SelectMethod'));

const AiConverter = lazy(() => import('scenes/DocumentFactory/Converter'));

const DataRoomOverview = lazy(() => import('scenes/Dataroom/Overview/DataRoomOverview'));

const TemplateList = lazy(() => import('scenes/DocumentFactory/TemplateList'));

const DocumentViewer = lazy(() => import('scenes/DocumentViewer'));

const SupportPage = lazy(() => import('scenes/Support/Support'));

const Interview = lazy(() => import('scenes/Interview/Interview'));

// Error handling
const NotFound = lazy(() => import('scenes/NotFound/NotFound'));

const privateRoutes = [
  {
    name: 'company',
    path: '/company',
    component: CompanyOverview,
    navbar: true,
    roles: [],
    cy: 'company__overview',
    routes: [
      {
        name: 'team',
        path: '/company/team',
        component: CompanyTeam,
        navbar: true,
        roles: [],
        cy: 'company__team',
      },
      {
        name: 'requests',
        path: '/company/requests',
        component: CompanyRequests,
        navbar: true,
        roles: [],
        permissions: ['canAdministrate'],
        cy: 'company__requests',
      },
    ],
  },
  {
    name: 'profile',
    path: '/profile',
    component: ProfilePersonal,
    cy: 'profile__personal',
    navbar: false,
    roles: [],
  },
  {
    name: 'Document creation',
    path: '/select-document-type',
    component: SelectFactoryDocument,
    navbar: false,
    roles: ['Treasurer', 'Bank', 'Legal'],
  },
  {
    name: 'Creation Method',
    path: '/select-creation-method/:method?',
    component: SelectFactoryMethod,
    navbar: false,
    roles: ['Treasurer', 'Bank', 'Legal'],
  },
  {
    name: 'AI Converter',
    path: '/ai-conversion/:eventId',
    component: AiConverter,
    navbar: false,
    roles: ['Treasurer', 'Bank', 'Legal'],
  },
  {
    name: 'Template List',
    path: '/template-list',
    component: TemplateList,
    navbar: false,
    roles: ['Treasurer', 'Bank', 'Legal'],
  },
  {
    name: 'Interview',
    path: '/document/:documentId/interview',
    component: Interview,
    navbar: false,
    roles: [],
  },
  {
    name: 'document viewer',
    path: '/document/:documentId/:finished?',
    component: DocumentViewer,
    navbar: false,
    roles: ['Treasurer', 'Bank', 'Legal'],
  },
  {
    name: 'Data room',
    path: '/',
    component: DataRoomOverview,
    navbar: true,
    roles: [],
  },
  {
    name: 'Support',
    path: '/support',
    component: SupportPage,
    navbar: false,
    roles: [],
  },
  {
    name: 'not found',
    path: '*',
    component: NotFound,
    navbar: false,
    roles: [],
  },
];

export default privateRoutes;
