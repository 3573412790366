import { useEffect } from 'react';
import { useGlobalStateValue } from 'store/store';
import { HeaderState } from '../store/storeTypes';

const useHeader = (payload: HeaderState) => {
  const {
    state: {
      appState: { header },
    },
    dispatch,
  } = useGlobalStateValue();

  useEffect(() => {
    if (payload.title && payload.title !== header.title) {
      dispatch({
        type: 'UPDATE_HEADER',
        payload,
      });
    }
  }, [dispatch, header.title, payload]);

  return null;
};

export default useHeader;
