import { Storage } from 'aws-amplify';

const ProcessRegion = process.env.REACT_APP_AWS_REGION as string;
const ProcessBucket = process.env.REACT_APP_S3_BUCKET as string;

Storage.configure({ region: ProcessRegion, bucket: ProcessBucket });

export const region = ProcessRegion;
export const bucket = ProcessBucket;

export default Storage;
