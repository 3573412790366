import { ReactNode, Dispatch, SetStateAction } from 'react';
import { Badge, MenuItem, Menu, IconButton } from '@material-ui/core';
import {
  AccountCircle,
  Mail as MailIcon,
  Notifications as NotificationsIcon,
} from '@material-ui/icons';

const LayoutRenderMobileMenu = (
  mobileMoreAnchorEl: ReactNode | null,
  setMobileMoreAnchorEl: Dispatch<SetStateAction<ReactNode>>
): JSX.Element => {
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  return (
    <Menu
      anchorEl={mobileMoreAnchorEl as Element}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );
};

export default LayoutRenderMobileMenu;
