import { ReactNode, FC, MouseEvent, useState } from 'react';
import {
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
  Theme,
  makeStyles,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { Cancel, MoreVert } from '@material-ui/icons';
import UserAvatar from 'components/UserAvatar/UserAvatar';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    zIndex: 1000,
    padding: theme.spacing(2),
    background: theme.palette.common.white,
  },
  withBoxshadow: {
    boxShadow:
      '0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14)',
  },
}));

interface Props {
  text?: string;
  onBack?: () => void;
  children?: ReactNode;
  contextData?: {
    avatar: string | undefined;
    name: string;
    label: string;
  };
  boxShadow?: boolean;
  handleSetSearch?: () => void;
  isSearch?: boolean;
  onLeave?: () => void;
}

const CollaborationHeader: FC<Props> = ({
  text,
  onBack,
  children,
  contextData,
  boxShadow = true,
  handleSetSearch,
  isSearch,
  onLeave,
}) => {
  const { container, withBoxshadow } = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLeave = () => {
    if (onLeave) {
      onLeave();
      handleClose();
    }
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        className={!boxShadow ? container : `${container} ${withBoxshadow}`}
      >
        <Grid item>
          {onBack && (
            <IconButton aria-label="back" onClick={onBack}>
              <ArrowBackIcon />
            </IconButton>
          )}
        </Grid>
        <Grid item xs>
          {text ? (
            <Typography variant="body1" align={onBack ? 'center' : 'left'}>
              {text}
            </Typography>
          ) : (
            <UserAvatar
              userName={contextData?.name || ''}
              userAvatarLabel={contextData?.label || ''}
              userAvatarKey={contextData?.avatar}
            />
          )}
        </Grid>
        <Grid item>
          <IconButton aria-label="search" onClick={handleSetSearch}>
            {isSearch ? <CloseIcon /> : <SearchIcon />}
          </IconButton>
        </Grid>
        {onLeave && !isSearch && (
          <Grid item>
            <IconButton
              aria-controls="menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVert />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLeave}>
                <ListItemIcon>
                  <Cancel fontSize="small" />
                </ListItemIcon>
                <Typography>Delete conversation</Typography>
              </MenuItem>
            </Menu>
          </Grid>
        )}
      </Grid>
      {children}
    </>
  );
};

export default CollaborationHeader;
