import { SET_NOTIFICATIONS } from 'store/actionTypes/notificationActionTypes';
import { NotificationState } from 'store/storeTypes';
import { notificationsInitialState } from 'store/initialState';
import { ActionTypes } from 'store/actionTypes/actionTypes';

const notificationsReducer = (
  state: NotificationState[] = notificationsInitialState,
  action: ActionTypes
): NotificationState[] => {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return action.payload;
    default:
      return state;
  }
};

export default notificationsReducer;
