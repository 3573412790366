import { Snackbar as MuiSnackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { FC } from 'react';
import { useGlobalStateValue } from 'store/store';

const Snackbar: FC = () => {
  const {
    dispatch,
    state: {
      snackbar: { snackbarOpen, snackbarType, snackbarMessage },
    },
  } = useGlobalStateValue();
  const handleClose = () => dispatch({ type: 'CLOSE_SNACKBAR' });

  return (
    <MuiSnackbar
      open={snackbarOpen}
      autoHideDuration={2500}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        elevation={6}
        variant="filled"
        severity={snackbarType}
      >
        {snackbarMessage}
      </Alert>
    </MuiSnackbar>
  );
};

export default Snackbar;
