import { FC, useState, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Toolbar,
  AppBar,
  IconButton,
  Tooltip,
  Badge,
  Typography,
  Box,
  makeStyles,
  Theme,
  Fab,
} from '@material-ui/core';
import {
  More,
  NotificationsOutlined,
  MessageOutlined,
  ArrowBack,
  Close,
  ChevronLeft,
  ChevronRight,
} from '@material-ui/icons';
import { useGlobalStateValue } from 'store/store';
import Navbar from 'containers/Nav/Navbar';
import Avatar from 'components/Avatar/Avatar';
import renderMobileMenu from './RenderMobileMenu';

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: theme.palette.common.white,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  toolbar: {
    minHeight: 96,
  },
  badge: {
    color: 'white',
  },
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  fabIcon: {
    position: 'absolute',
    top: theme.spacing(11),
    left: -theme.spacing(3),
    color: theme.palette.common.white,
    zIndex: 1202,
  },
  companyAvatar: {
    marginRight: theme.spacing(2),
  },
  menuIconActive: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
}));

interface Props {
  notificationsCount: number;
  messagesCount?: number;
}

const Nav: FC<Props> = ({ notificationsCount, messagesCount = 0 }) => {
  const { appBar, toolbar, grow, sectionDesktop, badge, sectionMobile, fabIcon, companyAvatar, menuIconActive } =
    useStyles();

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<ReactNode | null>(null);

  const mobileMenuId = 'primary-search-account-menu-mobile';

  const {
    state: {
      userInfo: { company },
      appState: {
        drawers,
        header: { title: headerTitle, isFocus, isClose, backUrl, withAvatar, onClose },
      },
    },
    dispatch,
  } = useGlobalStateValue();
  const { push } = useHistory();
  const { mainDrawer, notificationDrawer, collaborationDrawerOpen } = drawers;

  const saveDrawerAction = (key: string, value: boolean) => {
    sessionStorage.setItem(
      'bondify',
      JSON.stringify({
        ...drawers,
        [key]: value,
      })
    );
  };

  return (
    <>
      {renderMobileMenu(mobileMoreAnchorEl, setMobileMoreAnchorEl)}
      <AppBar className={appBar} elevation={0} position="sticky">
        <Toolbar className={toolbar}>
          {isFocus && (
            <IconButton
              onClick={() => {
                if (onClose) onClose();
                else push(backUrl || '/');
              }}
            >
              {isClose ? <Close color="action" /> : <ArrowBack color="action" />}
            </IconButton>
          )}
          {withAvatar && <Avatar imgKey={company?.avatar?.key} size={40} className={companyAvatar} />}
          <Typography data-cy="header__title" variant="h5" color="textPrimary">
            {headerTitle}
          </Typography>
          <div className={grow} />
          <div className={sectionDesktop}>
            <Tooltip title="Messages" placement="bottom">
              <IconButton
                aria-label="messages"
                onClick={() => {
                  saveDrawerAction('collaborationDrawerOpen', !drawers.collaborationDrawerOpen);
                  dispatch({ type: 'SET_COLLABORATION_DRAWER' });
                }}
                className={`${collaborationDrawerOpen ? menuIconActive : ''}`}
                data-cy="messages__icon"
              >
                <Badge badgeContent={messagesCount} color="error" classes={{ badge }}>
                  <MessageOutlined />
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title="Notifications" placement="bottom">
              <IconButton
                aria-label="notifications"
                onClick={() => {
                  saveDrawerAction('notificationDrawer', !drawers.notificationDrawer);
                  dispatch({ type: 'SET_NOTIFICATION_DRAWER' });
                }}
                className={`${notificationDrawer ? menuIconActive : ''}`}
                data-cy="notifications__icon"
              >
                <Badge badgeContent={notificationsCount} color="error" classes={{ badge }}>
                  <NotificationsOutlined />
                </Badge>
              </IconButton>
            </Tooltip>
          </div>
          <div className={sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={(event) => setMobileMoreAnchorEl(event.currentTarget)}
            >
              <More />
            </IconButton>
          </div>
        </Toolbar>
        <Box marginLeft={4}>
          <Navbar />
        </Box>
        <Fab
          className={fabIcon}
          onClick={() => {
            saveDrawerAction('mainDrawer', !drawers.mainDrawer);
            dispatch({ type: 'SET_MAIN_DRAWER' });
          }}
          size="small"
          color="primary"
          title="menu toggle"
        >
          {mainDrawer ? <ChevronLeft /> : <ChevronRight />}
        </Fab>
      </AppBar>
    </>
  );
};

export default Nav;
