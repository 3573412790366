import { FC } from 'react';
import { Typography, Button, Link } from '@material-ui/core';
import ErrorDev from 'assets/Notification/error_dev.svg';
import useHeader from 'hooks/useHeader';
import { useStyles } from 'components/Notification/Notification';

interface Props {
  onButtonClick: () => void;
}

const DevError: FC<Props> = ({ onButtonClick }) => {
  const { wrapper, link, image } = useStyles();
  useHeader({ title: '' });

  return (
    <div className={wrapper}>
      <Typography variant="h4" gutterBottom align="center">
        Error
      </Typography>
      <Typography variant="body1" gutterBottom align="center">
        Sorry, something went wrong on our end.
      </Typography>
      <Typography variant="body1" gutterBottom align="center">
        We are working on getting this fixed as soon as we can.
      </Typography>
      <Link className={link} href="/" onClick={onButtonClick}>
        <Button variant="contained" color="primary">
          Go Back
        </Button>
      </Link>
      <img className={image} src={ErrorDev} alt="error_server" />
    </div>
  );
};

export default DevError;
