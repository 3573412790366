import { createTheme, alpha } from '@material-ui/core/styles';

const materialTheme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        fontWeight: 600,
      },
    },
    MuiTab: {
      root: {
        fontSize: 14,
      },
    },
  },
  palette: {
    text: {
      primary: alpha('#000', 0.87),
      secondary: alpha('#000', 0.54),
      disabled: alpha('#000', 0.38),
      hint: alpha('#000', 0.38),
    },
    primary: {
      light: '#BBDEFB',
      main: '#0F4C81',
      dark: '#002554',
    },
    secondary: {
      light: '#FF6659',
      main: '#D32F2F',
      dark: '#9A0007',
    },
    info: {
      light: '#6293FF',
      main: '#1766CC',
      dark: '#003D9A',
      contrastText: '#00196B',
    },
    success: {
      light: '#60AD5E',
      main: '#2E7D32',
      dark: '#005005',
      contrastText: '#002700',
    },
    warning: {
      light: '#FFF263',
      main: '#FBC02D',
      dark: '#C49000',
      contrastText: '#663C00',
    },
    error: {
      light: '#FF6659',
      main: '#D32F2F',
      dark: '#9A0007',
      contrastText: '#611A15',
    },
    action: {
      active: alpha('#000', 0.54),
      hover: alpha('#000', 0.04),
      selected: alpha('#000', 0.08),
      disabled: alpha('#000', 0.26),
      disabledBackground: alpha('#000', 0.12),
      focus: alpha('#000', 0.12),
    },
    grey: {
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#78909c',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#D5D5D5',
      A200: '#AAAAAA',
      A400: '#616161',
      A700: '#303030',
    },
    common: {
      white: '#FFF',
      black: '#000',
    },
    background: {
      default: '#ECEFF1',
    },
  },
  typography: {
    fontFamily: 'Muli',
    h1: {
      fontSize: 96,
      fontWeight: 300,
      letterSpacing: -1.5,
    },
    h2: {
      fontSize: 60,
      fontWeight: 300,
      letterSpacing: -0.5,
    },
    h3: {
      fontSize: 48,
      fontWeight: 400,
      letterSpacing: 0,
    },
    h4: {
      fontSize: 34,
      fontWeight: 400,
      letterSpacing: 0.25,
    },
    h5: {
      fontSize: 24,
      fontWeight: 400,
      letterSpacing: 0,
    },
    h6: {
      fontSize: 20,
      fontWeight: 500,
      letterSpacing: 0.15,
    },
    subtitle1: {
      fontFamily: 'Open Sans',
      fontSize: 16,
      fontWeight: 600,
      letterSpacing: 0.15,
    },
    subtitle2: {
      fontFamily: 'Open Sans',
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: 0.1,
    },
    body1: {
      fontFamily: 'Open Sans',
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: 0.15,
    },
    body2: {
      fontFamily: 'Open Sans',
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: 0.15,
    },
    caption: {
      fontFamily: 'Open Sans',
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 0.4,
    },
    overline: {
      fontFamily: 'Open Sans',
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 1,
    },
    button: {
      fontFamily: 'Open Sans',
      fontSize: 12,
      fontWeight: 600,
      letterSpacing: 0.3,
    },
  },
});

export default materialTheme;
