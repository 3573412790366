import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Spinner from 'assets/Logo/bondify_spinner.svg';

const useStyles = makeStyles((theme: Theme) => ({
  spinner: {
    height: theme.spacing(8),
    margin: theme.spacing(1.25),
  },
}));

const Loader: React.FC<{ className?: string }> = ({ className }) => {
  const { spinner } = useStyles();
  const classNameProp = clsx([className, spinner]);
  return (
    <object className={classNameProp} type="image/svg+xml" data={Spinner}>
      bondify-loader
    </object>
  );
};

export default Loader;
