import { gql } from '@apollo/client';

export const USER_MAIN = gql`
  query getUser($id: ID!) {
    getUser(id: $id) {
      id
      firstname
      lastname
      email
      jobTitle
      phone
      onboardingStatus
      enabled
      userRole
      receiveNotificationEmails
      receiveCollaborationEmails
      avatar {
        bucket
        key
        region
      }
      company {
        id
        companyName
        corporateDomain
        avatar {
          key
          bucket
          region
        }
      }
      conversations {
        items {
          removedAt
          lastseen
          id
          conversation {
            id
            messages {
              items {
                author {
                  id
                  firstname
                  lastname
                }
                message
                id
                createdAt
              }
            }
            participants {
              items {
                id
                user {
                  firstname
                  jobTitle
                  lastname
                  id
                  avatar {
                    key
                  }
                }
              }
            }
          }
        }
      }
      notifications(limit: 100) {
        items {
          id
          message
          read
          createdAt
        }
      }
      transactions(filter: { removedAt: { notContains: ":" } }) {
        items {
          id
          transactions {
            id
            owner {
              avatar {
                key
              }
              companyName
            }
            participatingUsers(filter: { removedAt: { notContains: ":" } }) {
              items {
                id
                users {
                  avatar {
                    key
                  }
                  firstname
                  jobTitle
                  lastname
                  company {
                    companyName
                  }
                  id
                }
              }
            }
            maturityDate
            createdAt
            type
          }
        }
      }
    }
  }
`;

export const GET_USER_AUTH = gql`
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstname
      lastname
      email
      jobTitle
      phone
      onboardingStatus
      enabled
      userRole
      receiveNotificationEmails
      receiveCollaborationEmails
      avatar {
        bucket
        key
        region
      }
      company {
        id
        companyName
        avatar {
          key
          bucket
          region
        }
      }
    }
  }
`;
