import { ChatPanelTypes, SelectedChatTabType } from 'store/storeTypes';

export const UPDATE_NEW_TRANSACTION_ID = 'UPDATE_NEW_TRANSACTION_ID';
export const SET_ACTIVE_CHAT = 'SET_ACTIVE_CHAT';
export const SET_ACTIVE_USER_CONVERSATION_ID =
  'SET_ACTIVE_USER_CONVERSATION_ID';
export const SET_UNREAD_CONVERSATION_COUNT = 'SET_UNREAD_CONVERSATION_COUNT';
export const SET_CHAT_PANEL = 'SET_CHAT_PANEL';
export const SET_SELECTED_CHAT_TAB = 'SET_SELECTED_CHAT_TAB';
export const START_INSTANT_TRANSCATION_CHAT = 'START_INSTANT_TRANSCATION_CHAT';

interface TransactionChatProps {
  selectedChatTab: SelectedChatTabType;
  newTransactionId: string;
  activeUserConversationId: string;
  activeChatId: string;
  collaborationPanel: ChatPanelTypes;
}

export type ConversationActionTypes =
  | { type: typeof UPDATE_NEW_TRANSACTION_ID; payload: string }
  | { type: typeof SET_ACTIVE_CHAT; payload: string }
  | { type: typeof SET_ACTIVE_USER_CONVERSATION_ID; payload: string }
  | { type: typeof SET_UNREAD_CONVERSATION_COUNT; payload: number }
  | { type: typeof SET_CHAT_PANEL; payload: ChatPanelTypes }
  | { type: typeof SET_SELECTED_CHAT_TAB; payload: SelectedChatTabType }
  | {
      type: typeof START_INSTANT_TRANSCATION_CHAT;
      payload: TransactionChatProps;
    };
