import { makeStyles, Theme } from '@material-ui/core/styles';
import Loader from './Loader';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    zIndex: 1000,
    height: theme.spacing(8),
  },
}));

const PageLoader: React.FC = () => {
  const { root } = useStyles();
  return <Loader className={root} />;
};

export default PageLoader;
