import {
  OPEN_SNACKBAR,
  CLOSE_SNACKBAR,
} from 'store/actionTypes/snackbarActionTypes';
import { SnackbarState } from 'store/storeTypes';
import { initialStateSnackbar } from 'store/initialState';
import { ActionTypes } from 'store/actionTypes/actionTypes';

const snackbarReducer = (
  state: SnackbarState = initialStateSnackbar,
  action: ActionTypes
): SnackbarState => {
  switch (action.type) {
    case OPEN_SNACKBAR:
      return {
        ...state,
        snackbarOpen: true,
        snackbarMessage: action.payload.snackbarMessage,
        snackbarType: action.payload.snackbarType || 'success',
      };
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbarOpen: false,
      };
    default:
      return state;
  }
};

export default snackbarReducer;
