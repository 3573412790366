import { useState } from 'react';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Box,
  makeStyles,
  TextField,
  Typography,
  LinearProgress,
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Avatar from 'components/Avatar/Avatar';
import { useGlobalStateValue } from 'store/store';
import CollaborationHeader from '../CollaborationHeader/CollaborationHeader';

const useStyles = makeStyles(({ spacing, palette }) => ({
  box: {
    height: '100vh',
    background: palette.common.white,
    padding: `0 ${spacing(1)}px`,
  },
  listItemContainer: {
    background: palette.common.white,
    margin: `${spacing(1)}px auto`,
    borderRadius: spacing(0.5),
    boxShadow:
      '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
  },
  emptyResult: {
    padding: `${spacing(3)}px ${spacing(6)}`,
    marginTop: spacing(1),
  },
  progress: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
  },
}));

interface Props {
  onSearchChange: (value: string) => void;
  searchData: BasicUser | undefined;
  contextData: {
    avatar: string;
    name: string;
    label: string;
  };
  onUserSelected: (id: string) => void;
  loading: boolean;
}

const CreateCompanyChat: React.FC<Props> = ({
  onSearchChange,
  searchData,
  contextData,
  onUserSelected,
  loading,
}) => {
  const [searchInput, setSearchInput] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<string>('');

  const { box, listItemContainer, progress, emptyResult } = useStyles();
  const { dispatch } = useGlobalStateValue();

  const handleItemClick = (id: string) => {
    setSelectedItem(id);
    onUserSelected(id);
  };

  return (
    <>
      <CollaborationHeader
        onBack={() => dispatch({ type: 'SET_CHAT_PANEL', payload: '' })}
        contextData={contextData}
      >
        <Box padding={2} marginTop={1}>
          <TextField
            fullWidth
            label="Search User"
            variant="outlined"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { value } = e.target;
              setSearchInput(value);
              onSearchChange(value);
            }}
            value={searchInput}
            autoFocus
          />
        </Box>
      </CollaborationHeader>
      <Box className={box}>
        {searchData.length === 0 ? (
          <Typography align="center" className={emptyResult}>
            No user found.
          </Typography>
        ) : (
          <List>
            {searchData.map((item: BasicUser) => (
              <ListItem
                button
                disabled={loading}
                classes={{ container: listItemContainer }}
                onClick={() => handleItemClick(item.id)}
                key={item.id}
              >
                {loading && selectedItem === item.id && (
                  <LinearProgress classes={{ root: progress }} />
                )}
                <ListItemAvatar>
                  <Avatar size={40} imgKey={item.avatar?.key} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      variant="body1"
                      gutterBottom
                    >{`${item.firstname} ${item.lastname}`}</Typography>
                  }
                  secondary={
                    <Typography variant="body2" color="textSecondary">
                      {item.jobTitle}
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  <ArrowForwardIosIcon color="primary" />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    </>
  );
};

export default CreateCompanyChat;
