import jwtDecode from 'jwt-decode';
import { Auth } from 'aws-amplify';
import { parseJson } from './helpers';

export default async (): Promise<string[]> => {
  const token = (await Auth.currentSession()).getIdToken().getJwtToken();
  const sessionIdInfo: any = await jwtDecode(token);
  const { permissions } = sessionIdInfo;
  const permissionsParsed = parseJson(permissions, {});
  const permissionsArray = Object.keys(permissionsParsed).filter(
    (permission: string) => permissionsParsed[permission]
  );

  return permissionsArray;
};
