import { gql } from '@apollo/client';

export const USER_CHATS_LIST = gql`
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      conversations {
        items {
          removedAt
          lastseen
          id
          conversation {
            id
            messages {
              items {
                author {
                  id
                  firstname
                  lastname
                }
                message
                id
                createdAt
              }
            }
            participants {
              items {
                user {
                  firstname
                  jobTitle
                  lastname
                  id
                  avatar {
                    key
                  }
                }
              }
            }
            transaction {
              id
              owner {
                avatar {
                  key
                }
                companyName
              }
              type
              maturityDate
            }
          }
        }
      }
    }
  }
`;

export const USER_COMPANY_SEARCH = gql`
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      users {
        items {
          firstname
          lastname
          id
          jobTitle
          avatar {
            key
          }
        }
      }
    }
  }
`;
