import { FC } from 'react';
import Avatar from 'components/Avatar/Avatar';
import { Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

interface UserAvatarProps {
  userName: string;
  userAvatarKey: string | undefined;
  userAvatarLabel?: string;
  loading?: boolean;
}

const UserAvatar: FC<UserAvatarProps> = ({
  userName,
  userAvatarKey,
  userAvatarLabel,
  loading,
}) => (
  <Grid container alignItems="center" spacing={2}>
    <Grid item>
      {loading ? (
        <Skeleton variant="circle" width={40} height={40} />
      ) : (
        <Avatar size={40} imgKey={userAvatarKey} />
      )}
    </Grid>
    <Grid item xs>
      <Grid container direction="column">
        {loading ? (
          <>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </>
        ) : (
          <>
            <Typography variant="body2">{userName}</Typography>
            <Typography variant="body2" color="textSecondary">
              {userAvatarLabel}
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  </Grid>
);

export default UserAvatar;
