import { FC, useEffect, useState } from 'react';
import { useHistory, Route } from 'react-router-dom';
import { useGlobalStateValue } from 'store/store';
import Main from 'containers/Main';
import { Auth } from 'aws-amplify';
import PageLoader from 'components/Loader/PageLoader';
import publicRoutes from './publicRoutes';
import RouteList from './RouteList';

const Router: FC = () => {
  const {
    state: {
      userInfo: { authenticated, id: userId },
      conversation: { activeChatId },
    },
    dispatch,
  } = useGlobalStateValue();
  const [cognitoLoading, setCognitoLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const {
          attributes: { sub },
        } = user;

        dispatch({
          type: 'UPDATE_USER_INFO',
          payload: {
            id: sub,
            authenticated: true,
          },
        });
        setCognitoLoading(false);
      } catch (error) {
        dispatch({
          type: 'UPDATE_AUTH',
          payload: false,
        });
        setCognitoLoading(false);
        history.replace('/login');
      }
    })();
  }, [history, dispatch]);

  return (
    <main>
      {cognitoLoading && <PageLoader />}
      <RouteList routes={publicRoutes} />
      <Route path="/" exact={false}>
        {authenticated && (
          <Main
            userId={userId}
            dispatch={dispatch}
            activeChatId={activeChatId}
          />
        )}
      </Route>
    </main>
  );
};

export default Router;
