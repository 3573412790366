import { gql } from '@apollo/client';

export const NEW_CONVERSATION = gql`
  mutation StartNewConversation($users: [String!]) {
    startNewConversation(users: $users, conversationName: "") {
      conversationId
      userConversationId
    }
  }
`;

export const CREATE_NEW_MESSAGE = gql`
  mutation CreateConversationMessage($input: CreateConversationMessageInput!) {
    createConversationMessage(input: $input) {
      id
      createdAt
      conversationId
      message
      updatedAt
      author {
        id
        firstname
        lastname
      }
    }
  }
`;

export const NEW_MESSAGE_SUBSCRIPTION = gql`
  subscription OnCreateConversationMessage($conversationId: ID!) {
    onCreateConversationMessage(conversationId: $conversationId) {
      id
      createdAt
      conversationId
      message
      updatedAt
      author {
        id
        firstname
        lastname
      }
    }
  }
`;

export const LEAVE_CONVERSATION = gql`
  mutation EndConversation($conversationId: ID!) {
    endConversation(conversationId: $conversationId) {
      conversationId
    }
  }
`;

export const UPDATE_LAST_SEEN = gql`
  mutation UpdateUserConversation($input: UpdateUserConversationInput!) {
    updateUserConversation(input: $input) {
      id
    }
  }
`;
