import { ReactNode } from 'react';
import { useGlobalStateValue } from 'store/store';
import { userInfoInitialState } from 'store/initialState';
import { Auth } from 'aws-amplify';
import { useHistory, Link } from 'react-router-dom';
import { ListItem, makeStyles, List, Drawer } from '@material-ui/core';
import {
  Work,
  ContactSupport,
  ExitToApp,
  InsertDriveFile,
} from '@material-ui/icons';
import clsx from 'clsx';
import { MenuTypes } from 'store/storeTypes';
import Logo from 'components/Logo';
import Avatar from 'components/Avatar/Avatar';
import { ReactComponent as LogoSmall } from 'assets/Logo/logo_small.svg';
import { isBondifier } from 'utils/helpers';
import LayoutMenuItem from './MainMenuItem';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: theme.spacing(30),
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  drawerOpen: {
    width: theme.spacing(30),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(8),
  },
  paper: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  logoContainer: {
    minHeight: 150,
    position: 'relative',
    overflow: 'hidden',
  },
  navbar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  profile: {
    background: theme.palette.primary.dark,
  },
  gutters: {
    padding: theme.spacing(2),
  },
}));

interface MenuItems {
  title: MenuTypes;
  to: string;
  icon: ReactNode;
}

const menuItems: MenuItems[] = [
  {
    title: 'Data Room',
    to: '/',
    icon: <InsertDriveFile />,
  },
  {
    title: 'Organization',
    to: '/company',
    icon: <Work />,
  },
];

const LayoutMainDrawer: React.FC = () => {
  const {
    drawer,
    drawerOpen,
    drawerClose,
    navbar,
    paper,
    logoContainer,
    profile,
    gutters,
  } = useStyles();
  const {
    dispatch,
    state: {
      appState: {
        drawers: { mainDrawer },
        selectedMenu,
      },
      userInfo: { avatar, userRole, firstname, lastname, email },
    },
  } = useGlobalStateValue();
  const history = useHistory();

  const logOut = async () => {
    await Auth.signOut().catch((err) => {
      throw new Error(err.message);
    });

    dispatch({
      type: 'UPDATE_USER_INFO',
      payload: userInfoInitialState,
    });

    history.replace('/login');
  };

  return (
    <Drawer
      variant="permanent"
      className={clsx(drawer, {
        [drawerOpen]: mainDrawer,
        [drawerClose]: !mainDrawer,
      })}
      classes={{
        paper: clsx(paper, {
          [drawerOpen]: mainDrawer,
          [drawerClose]: !mainDrawer,
        }),
      }}
      data-cy="app__drawer"
    >
      <List className={logoContainer}>
        <ListItem title="logo-link" to="/" component={Link}>
          {mainDrawer ? <Logo /> : <LogoSmall width={36} height={50} />}
        </ListItem>
      </List>
      <div className={navbar}>
        <List disablePadding>
          {menuItems.map((item) => (
            <LayoutMenuItem
              key={item.title}
              selected={item.title === selectedMenu}
              title={item.title}
              to={item.to}
              icon={item.icon}
              cy={item.title.toLowerCase()}
            />
          ))}
        </List>
        <div>
          {isBondifier(email) && (
            <List disablePadding>
              <LayoutMenuItem
                selected={selectedMenu === 'Support'}
                title="Support"
                to="/support"
                icon={<ContactSupport />}
                cy="support"
              />
            </List>
          )}
          <List disablePadding>
            <LayoutMenuItem
              title="Logout"
              onClick={logOut}
              to="/"
              cy="logout"
              icon={<ExitToApp />}
            />
          </List>
          <List disablePadding className={profile}>
            <LayoutMenuItem
              title={`${firstname} ${lastname}`}
              subtitle={userRole}
              to="/profile"
              icon={<Avatar imgKey={avatar?.key} size={40} />}
              gutters={gutters}
              name="Profile"
              cy="profile"
            />
          </List>
        </div>
      </div>
    </Drawer>
  );
};

export default LayoutMainDrawer;
