import { useState, useMemo } from 'react';
import { makeStyles, TextField, Box } from '@material-ui/core';
import { useGlobalStateValue } from 'store/store';
import { SpeedDial, SpeedDialIcon } from '@material-ui/lab';
import CollaborationListItems from './CollaborationListItems';
import CollaborationHeader from '../CollaborationHeader/CollaborationHeader';
import { filterChats } from '../collaboration-service';

const useStyles = makeStyles(({ spacing }) => ({
  speedDial: {
    position: 'fixed',
    bottom: spacing(3),
    right: spacing(3),
  },
}));

interface Props {
  chats: ChatType[];
}

const CollaborationList: React.FC<Props> = ({ chats }) => {
  const { speedDial } = useStyles();
  const [search, setSearch] = useState<boolean>(false);
  const [companyChatSearchInput, setCompanyChatSearchInput] =
    useState<string>('');
  const {
    dispatch,
    state: {
      userInfo: { id: userId },
    },
  } = useGlobalStateValue();

  const filteredchats = useMemo(
    () => filterChats(companyChatSearchInput, userId, chats),
    [companyChatSearchInput, userId, chats]
  );

  const onChatClick = (chatId: string, userConversationId: string) => () => {
    dispatch({ type: 'SET_ACTIVE_CHAT', payload: chatId });
    dispatch({
      type: 'SET_ACTIVE_USER_CONVERSATION_ID',
      payload: userConversationId,
    });
    dispatch({ type: 'SET_CHAT_PANEL', payload: 'room' });
  };

  const onCreateConversation = () => {
    dispatch({
      type: 'SET_CHAT_PANEL',
      payload: 'startCompanyChat',
    });
  };

  const handleSetSearch = () => {
    if (search) {
      setCompanyChatSearchInput('');
    }
    setSearch(!search);
  };

  const handleSearchFieldOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => setCompanyChatSearchInput(event.target.value);

  return (
    <>
      <CollaborationHeader
        text="Conversations"
        handleSetSearch={handleSetSearch}
        isSearch={search}
        boxShadow={false}
      >
        {search && (
          <Box padding={2}>
            <TextField
              fullWidth
              id="outlined-helperText"
              label="Search Conversation"
              variant="outlined"
              onChange={handleSearchFieldOnChange}
              value={companyChatSearchInput}
              autoFocus
            />
          </Box>
        )}
      </CollaborationHeader>
      <CollaborationListItems
        userId={userId}
        list={filteredchats}
        onClick={onChatClick}
        isSearch={search}
      />
      <SpeedDial
        ariaLabel="create-conversation"
        className={speedDial}
        icon={<SpeedDialIcon />}
        direction="up"
        open={false}
        onClick={onCreateConversation}
      />
    </>
  );
};

export default CollaborationList;
