import { useState, Fragment, useEffect, useMemo } from 'react';
import { Send } from '@material-ui/icons';
import {
  makeStyles,
  Theme,
  List,
  ListItem,
  ListItemText,
  Typography,
  Chip,
  TextField,
  Box,
  Button,
} from '@material-ui/core';
import groupBy from 'lodash.groupby';
import { formatDate, formatMessageDate } from 'utils/helpers';
import { useGlobalStateValue } from 'store/store';
import { useMutation } from '@apollo/client';
import {
  LEAVE_CONVERSATION,
  UPDATE_LAST_SEEN,
} from 'operations/mutations/messaging';
import { USER_CHATS_LIST } from 'operations/queries/messaging';
import CollaborationHeader from '../CollaborationHeader/CollaborationHeader';
import LeaveConfirmation from './LeaveConfirmation';
import { filterMessages } from '../collaboration-service';

const useStyles = makeStyles((theme: Theme) => ({
  messagesList: {
    paddingBottom: theme.spacing(3),
    overflowY: 'auto',
    height: 'calc(100vh - 330px)',
  },
  sendButton: {
    float: 'right',
  },
  messageArea: {
    border: 'none',
    outline: 'none',
    fontFamily: 'Open Sans',
    fontSize: '1.1em',
    width: '100%',
    resize: 'none',
  },
  inputContainer: {
    borderTop: '1px solid #e3e3e3',
    position: 'fixed',
    bottom: 0,
    padding: 6,
    width: 350,
  },
  myMessage: {
    background: '#dceffd',
    width: '80%',
    marginLeft: 'auto',
    marginRight: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    boxShadow:
      '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
  },
  notMyMessage: {
    background: 'white',
    width: '80%',
    marginRight: 'auto',
    borderRadius: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    boxShadow:
      '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
  },
  myMessageTime: {
    textAlign: 'right',
    margin: theme.spacing(1),
  },
  noMyMessageTime: {
    textAlign: 'left',
    margin: theme.spacing(1),
  },
  emptyConversation: {
    width: '66%',
    margin: '28px auto',
  },
  chipStyle: {
    marginTop: theme.spacing(2.5),
    marginLeft: 132,
  },
  noSearchResultText: {
    textAlign: 'center',
    marginTop: theme.spacing(3),
  },
  wordBreak: {
    wordBreak: 'break-word',
  },
}));

interface Props {
  chats: ChatType[];
  onSend: (message: string, conversationId: string, senderId: string) => void;
}

const CollaborationRoom: React.FC<Props> = ({ chats, onSend }) => {
  const {
    inputContainer,
    sendButton,
    messageArea,
    myMessage,
    notMyMessage,
    messagesList,
    myMessageTime,
    noMyMessageTime,
    emptyConversation,
    chipStyle,
    noSearchResultText,
    wordBreak,
  } = useStyles();
  const [search, setSearch] = useState<boolean>(false);
  const [searchInputContent, setSearchInputContent] = useState<string>('');
  const {
    dispatch,
    state: {
      userInfo: { id: userId },
      conversation: { activeChatId, activeUserConversationId, selectedChatTab },
    },
  } = useGlobalStateValue();

  const [textMessage, setTextMessage] = useState<string>('');
  const [confirmLeave, setConfirmLeave] = useState<boolean>(false);
  const foundChat = chats.find(({ id }: ChatType) => id === activeChatId);
  const [updateLastSeen] = useMutation(UPDATE_LAST_SEEN);

  useEffect(() => {
    updateLastSeen({
      variables: {
        input: {
          id: activeUserConversationId,
          lastseen: new Date().toISOString(),
        },
      },
      refetchQueries: [{ query: USER_CHATS_LIST, variables: { id: userId } }],
    });
  }, [activeUserConversationId, userId, updateLastSeen]);

  const handleOnBack = () => {
    if (selectedChatTab === 0) {
      dispatch({ type: 'SET_CHAT_PANEL', payload: '' });
    } else {
      dispatch({ type: 'SET_CHAT_PANEL', payload: 'transactionsChatList' });
    }
    updateLastSeen({
      variables: {
        input: {
          id: activeUserConversationId,
          lastseen: new Date().toISOString(),
        },
      },
      refetchQueries: [{ query: USER_CHATS_LIST, variables: { id: userId } }],
    });
    dispatch({ type: 'SET_ACTIVE_CHAT', payload: '' });
  };

  const [leaveConversation] = useMutation(LEAVE_CONVERSATION, {
    onCompleted() {
      dispatch({ type: 'SET_CHAT_PANEL', payload: '' });
    },
    refetchQueries: [{ query: USER_CHATS_LIST, variables: { id: userId } }],
  });

  const handleMessageSend = () => {
    if (!textMessage || !foundChat) return;

    onSend(textMessage, foundChat.id, userId);
    setTextMessage('');
  };

  const handleLeaveConversation = () => {
    leaveConversation({ variables: { conversationId: foundChat?.id } });
  };

  const user = foundChat?.participants?.find(
    ({ id }: BasicUser) => id !== userId
  );
  const grouped = groupBy(foundChat?.messages, (item) =>
    formatMessageDate(item.createdAt)
  );

  const filteredGroupedMessages = useMemo(
    () => filterMessages(searchInputContent, grouped),
    [searchInputContent, grouped]
  );

  const handleSetSearch = () => {
    if (search) {
      setSearchInputContent('');
    }
    setSearch(!search);
  };

  return (
    <>
      <CollaborationHeader
        onBack={handleOnBack}
        contextData={{
          avatar: foundChat ? user.avatar?.key : '',
          name: foundChat ? `${user.firstname} ${user.lastname}` : 'loading...',
          label: user?.jobTitle,
        }}
        handleSetSearch={handleSetSearch}
        onLeave={() => setConfirmLeave(true)}
        isSearch={search}
      >
        {search && (
          <Box padding={2}>
            <TextField
              fullWidth
              id="outlined-helperText"
              label="Search message"
              variant="outlined"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setSearchInputContent(event.target.value)
              }
              value={searchInputContent}
              autoFocus
            />
          </Box>
        )}
      </CollaborationHeader>
      {confirmLeave ? (
        <LeaveConfirmation
          name={`${user.firstname} ${user.lastname}`}
          onLeave={handleLeaveConversation}
          onCancel={() => setConfirmLeave(false)}
        />
      ) : (
        <>
          <List disablePadding className={messagesList}>
            {foundChat?.messages.length === 0 ? (
              <Typography
                variant="body1"
                align="center"
                classes={{ root: emptyConversation }}
              >
                Start your conversation with
                {` ${user.firstname} ${user.lastname}`}
              </Typography>
            ) : (
              <>
                {Object.keys(filteredGroupedMessages).length ? (
                  Object.keys(filteredGroupedMessages).map((date: string) => (
                    <Fragment key={date}>
                      <Chip label={date} classes={{ root: chipStyle }} />
                      {filteredGroupedMessages[date].map(
                        ({
                          message,
                          id,
                          author,
                          createdAt,
                        }: CollaborationMessage) => {
                          const sentByMe = author.id === userId;
                          return (
                            <Fragment key={id}>
                              <Typography
                                variant="body2"
                                component="div"
                                classes={{
                                  root: sentByMe
                                    ? myMessageTime
                                    : noMyMessageTime,
                                }}
                              >
                                {formatDate(createdAt, 'HH:mm')}
                              </Typography>
                              <ListItem
                                classes={{
                                  root: sentByMe ? myMessage : notMyMessage,
                                }}
                              >
                                <ListItemText
                                  className={wordBreak}
                                  primary={message}
                                />
                              </ListItem>
                            </Fragment>
                          );
                        }
                      )}
                    </Fragment>
                  ))
                ) : (
                  <>
                    <Typography
                      variant="body2"
                      component="div"
                      className={noSearchResultText}
                    >
                      loading...
                    </Typography>
                  </>
                )}
              </>
            )}
          </List>
          <div className={inputContainer}>
            <textarea
              className={messageArea}
              id="w3review"
              name="w3review"
              rows={4}
              cols={40}
              value={textMessage}
              onChange={(e) => setTextMessage(e.target.value)}
            />

            <Button
              color="primary"
              variant="contained"
              type="submit"
              onClick={handleMessageSend}
              className={sendButton}
              startIcon={<Send />}
            >
              Send
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default CollaborationRoom;
