import { ReactNode } from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  makeStyles,
  Theme,
} from '@material-ui/core/';
import { Link } from 'react-router-dom';

interface Props {
  title: string;
  icon: ReactNode;
  to: string;
  cy: string;
  selected?: boolean;
  onClick?: () => void;
  subtitle?: string;
  gutters?: string;
  name?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  menuItem: {
    paddingLeft: theme.spacing(3),
  },
  secondaryText: {
    color: theme.palette.primary.light,
  },
  iconColor: {
    color: theme.palette.common.white,
  },
}));

const LayoutMenuItem: React.FC<Props> = ({
  title,
  subtitle,
  to,
  icon,
  onClick,
  selected = false,
  gutters,
  name,
  cy,
}: Props) => {
  const { menuItem, secondaryText, iconColor } = useStyles();
  return (
    <Tooltip title={title} placement="right">
      <ListItem
        classes={{ gutters: gutters || menuItem }}
        selected={selected}
        component={Link}
        button
        to={to}
        data-cy={`menu__${cy}`}
        onClick={onClick}
      >
        <ListItemIcon className={iconColor} title={name}>
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={title}
          secondary={subtitle}
          classes={{ secondary: secondaryText, primary: 'ellipsis-overflow' }}
        />
      </ListItem>
    </Tooltip>
  );
};

LayoutMenuItem.defaultProps = {
  selected: false,
  onClick: () => {},
  subtitle: '',
  gutters: '',
  name: '',
};

export default LayoutMenuItem;
