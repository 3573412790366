import { FC, createContext, useContext, useReducer, Dispatch } from 'react';
import { GlobalState } from './storeTypes';
import { globalInitialState } from './initialState';
import { ActionTypes } from './actionTypes/actionTypes';
import conversationReducer from './reducers/conversationReducer';
import snackbarReducer from './reducers/snackbarReducer';
import userReducer from './reducers/userReducer';
import notificationsReducer from './reducers/notificationReducer';
import appReducer from './reducers/appReducer';

interface GlobalStoreContextProps {
  state: GlobalState;
  dispatch: Dispatch<ActionTypes>;
}

const reducer = (state: GlobalState, action: ActionTypes) => ({
  conversation: conversationReducer(state.conversation, action),
  snackbar: snackbarReducer(state.snackbar, action),
  userInfo: userReducer(state.userInfo, action),
  notifications: notificationsReducer(state.notifications, action),
  appState: appReducer(state.appState, action),
});

export const GlobalStoreContext = createContext<GlobalStoreContextProps>({
  state: globalInitialState,
  dispatch: () => {
    throw new Error('No GlobalContext available');
  },
});

export const GlobalStoreProvider: FC = ({ children }) => {
  const cache = JSON.parse(sessionStorage.getItem('bondify') || '{}');
  const init: GlobalState = {
    ...globalInitialState,
    appState: { ...globalInitialState.appState, drawers: cache },
  };
  const [state, dispatch] = useReducer(reducer, init);

  return (
    <GlobalStoreContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalStoreContext.Provider>
  );
};

export const useGlobalStateValue = () => useContext(GlobalStoreContext);
