import { Suspense, FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import PageLoader from 'components/Loader/PageLoader';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import DevError from 'containers/DevError';
import Apollo from 'containers/Apollo';
import Router from 'router/Router';
import SnackBar from 'components/Snackbar/Snackbar';
import './App.css';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    attachStacktrace: true,
    release: 'platform-frontend@1.2.1',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.2,
  });
}

function ErrorFallback({ error, resetError }: any) {
  if (error.name === 'ChunkLoadError') {
    window.location.reload();
    return <div />;
  }
  return <DevError onButtonClick={resetError} />;
}

const App: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <Apollo>
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        <BrowserRouter>
          <SnackBar />
          <Router />
        </BrowserRouter>
      </Sentry.ErrorBoundary>
    </Apollo>
  </Suspense>
);

export default Sentry.withProfiler(App);
