import { FC, useMemo } from 'react';
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { useLocation, NavLink } from 'react-router-dom';
import privateRoutes from 'router/privateRoutes';
import { filterNavItems, flattenRoutes } from 'utils/helpers';
import { useGlobalStateValue } from 'store/store';

const useStyles = makeStyles((theme: Theme) => ({
  nav: {
    width: 'calc(100% + 12px)',
  },
  navLink: {
    textDecoration: 'none',
    color: theme.palette.grey[700],
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: 'capitalize',
    display: 'inline-block',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(4)}px`,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  navLinkActive: {
    color: theme.palette.primary.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  navLinkText: {
    fontSize: theme.spacing(2),
    lineHeight: `${theme.spacing(3)}px`,
  },
}));

const Navbar: FC = () => {
  const { nav, navLink, navLinkActive, navLinkText } = useStyles();

  const {
    state: {
      userInfo: { permissions },
    },
  } = useGlobalStateValue();

  const location = useLocation();

  const navbarItems = useMemo(() => {
    const filteredNavItems = filterNavItems(
      flattenRoutes(privateRoutes),
      location.pathname,
      permissions
    );

    return filteredNavItems;
  }, [location.pathname, permissions]);

  return (
    <Grid container spacing={3} className={nav}>
      {navbarItems.map((route) => (
        <Grid key={route.path} item>
          <NavLink
            exact
            to={route.path}
            className={navLink}
            activeClassName={navLinkActive}
            data-cy={route.cy}
          >
            <Typography className={navLinkText}>{route.name}</Typography>
          </NavLink>
        </Grid>
      ))}
    </Grid>
  );
};

export default Navbar;
