import {
  UserInfoState,
  SnackbarState,
  DrawersState,
  MenuState,
  ConversationState,
  GlobalState,
  NotificationState,
  HeaderState,
  AppState,
} from './storeTypes';

export const userInfoInitialState: UserInfoState = {
  authenticated: false,
  avatar: {
    bucket: '',
    key: '',
    region: '',
  },
  company: {
    avatar: {
      bucket: '',
      key: '',
      region: '',
    },
    corporateDomain: '',
    companyName: '',
    id: '',
  },
  email: '',
  enabled: true,
  firstname: '',
  permissions: [],
  id: '',
  jobTitle: '',
  lastname: '',
  onboardingStatus: '',
  phone: '',
  userRole: '',
  receiveCollaborationEmails: false,
  receiveNotificationEmails: false,
};

export const mainQueryInitialState = {
  enabled: true,
  conversations: {
    items: [],
  },
  id: '',
  notifications: {
    items: [],
  },
  transactions: {
    items: [],
  },
};

export const initialStateSnackbar: SnackbarState = {
  snackbarOpen: false,
  snackbarMessage: '',
  snackbarType: undefined,
};

const drawerInitialState: DrawersState = {
  mainDrawer: true,
  notificationDrawer: false,
  collaborationDrawerOpen: false,
};

export const conversationInitialState: ConversationState = {
  activeChatId: '',
  activeUserConversationId: '',
  userConversationId: '',
  collaborationPanel: '',
  newTransactionId: '',
  selectedChatTab: 0,
  unreadConversationsCount: 0,
};

const menuInitialState: MenuState = 'Dashboard';

export const notificationsInitialState: NotificationState[] = [];

const headerInitialState: HeaderState = {
  title: '',
  isFocus: false,
  isClose: false,
  backUrl: '',
  withAvatar: false,
  onClose: () => {},
};

export const AppInitialState: AppState = {
  selectedMenu: menuInitialState,
  drawers: drawerInitialState,
  header: headerInitialState,
};

export const globalInitialState: GlobalState = {
  snackbar: initialStateSnackbar,
  conversation: conversationInitialState,
  notifications: notificationsInitialState,
  userInfo: userInfoInitialState,
  appState: {
    drawers: drawerInitialState,
    header: headerInitialState,
    selectedMenu: menuInitialState,
  },
};
