import { FC, ReactNode } from 'react';
import { Button, Typography, makeStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import ServerError from 'assets/Notification/error_server.svg';
import PageNotFound from 'assets/Notification/page_not_found.svg';
import EmptyIcon from 'assets/Notification/no_contract.svg';
import UploadMedia from 'assets/Notification/no_contract_new.svg';
import DocumentError from 'assets/Notification/error-document.svg';
import useHeader from 'hooks/useHeader';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingTop: theme.spacing(6),
  },
  link: {
    textDecoration: 'none',
    margin: `${theme.spacing(3)}px 0`,
    display: 'block',
    textAlign: 'center',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  image: {
    height: theme.spacing(20),
    width: '100%',
    marginBottom: theme.spacing(3),
  },
}));

interface Props {
  title?: string;
  subTitle?: string;
  description?: string | ReactNode;
  type?: string;
  hideBackButton?: boolean;
}

const defaultProps = {
  title: 'Server Error',
  subTitle: 'Sorry, something went wrong on our end.',
  description: 'We’re experincing a server problem.',
  type: 'server',
  hideBackButton: false,
};

const allIcons: Record<string, string> = {
  server: ServerError,
  notFound: PageNotFound,
  editor: DocumentError,
  empty: EmptyIcon,
  upload: UploadMedia,
};

const Notification: FC<Props> = ({
  title,
  subTitle,
  type = 'server',
  description,
  hideBackButton = false,
}) => {
  const { wrapper, link, image } = useStyles();
  useHeader({ title: '' });

  return (
    <div className={wrapper}>
      <img className={image} src={allIcons[type]} alt="notification_element" />
      {title && (
        <Typography
          color="textSecondary"
          variant="h4"
          gutterBottom
          align="center"
        >
          {title}
        </Typography>
      )}
      <Typography
        color="textSecondary"
        variant="body1"
        gutterBottom
        align="center"
      >
        {subTitle}
      </Typography>
      <Typography
        color="textSecondary"
        variant="body1"
        gutterBottom
        align="center"
      >
        {description}
      </Typography>
      {!hideBackButton && (
        <NavLink className={link} to="/">
          <Button variant="contained" color="primary">
            Go Back
          </Button>
        </NavLink>
      )}
    </div>
  );
};

Notification.defaultProps = defaultProps;

export default Notification;
