import { UserInfoState, UserInfoCompany } from 'store/storeTypes';

export const UPDATE_USER_INFO_PERMISSIONS = 'UPDATE_USER_INFO_PERMISSIONS';
export const UPDATE_USER_INFO_COMPANY = 'UPDATE_USER_INFO_COMPANY';
export const UPDATE_USER_INFO_COMPANY_AVATAR_KEY =
  'UPDATE_USER_INFO_COMPANY_AVATAR_KEY';
export const UPDATE_USER_INFO_EMAIL = 'UPDATE_USER_INFO_EMAIL';
export const UPDATE_USER_INFO_AVATAR_KEY = 'UPDATE_USER_INFO_AVATAR_KEY';
export const UPDATE_USER_INFO_PHONE = 'UPDATE_USER_INFO_PHONE';
export const UPDATE_AUTH = 'UPDATE_AUTH';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';

export type UserActionTypes =
  | { type: typeof UPDATE_AUTH; payload: boolean }
  | {
      type: typeof UPDATE_USER_INFO;
      payload:
        | UserInfoState
        | {
            firstname?: string;
            lastname?: string;
            jobTitle?: string;
            id?: string;
          };
    }
  | { type: typeof UPDATE_USER_INFO_PERMISSIONS; payload: string[] }
  | { type: typeof UPDATE_USER_INFO_COMPANY; payload: UserInfoCompany }
  | { type: typeof UPDATE_USER_INFO_COMPANY_AVATAR_KEY; payload: string }
  | { type: typeof UPDATE_USER_INFO_EMAIL; payload: string }
  | { type: typeof UPDATE_USER_INFO_AVATAR_KEY; payload: string }
  | { type: typeof UPDATE_USER_INFO_PHONE; payload: string };
