import { HeaderState, MenuTypes } from 'store/storeTypes';

export const SET_MAIN_DRAWER = 'SET_MAIN_DRAWER';
export const SET_NOTIFICATION_DRAWER = 'SET_NOTIFICATION_DRAWER';
export const SET_COLLABORATION_DRAWER = 'SET_COLLABORATION_DRAWER';
export const UPDATE_HEADER = 'UPDATE_HEADER';
export const UPDATE_MENU_SELECTION = 'UPDATE_MENU_SELECTION';

export type AppActionTypes =
  | { type: typeof SET_MAIN_DRAWER }
  | { type: typeof SET_NOTIFICATION_DRAWER }
  | { type: typeof SET_COLLABORATION_DRAWER }
  | {
      type: typeof UPDATE_HEADER;
      payload: HeaderState;
    }
  | {
      type: typeof UPDATE_MENU_SELECTION;
      payload: MenuTypes;
    }
  | {
      type: typeof UPDATE_HEADER;
      payload: HeaderState;
    };
