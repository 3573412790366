import { format, isValid, isSameDay, differenceInDays, isToday, isYesterday } from 'date-fns';

const ContractSteps = ['Not Started', 'Interview', 'Draft', 'Negotiation', 'Agreed', 'Signed', 'Download'];

/**
 * @deprecated use `src/utils/dateTime.ts` `getOnlyDateRepresentation` or `getDateTimeRepresentation` instead.
 * Reason for deprecation: date representation (ie locale) should be managed centrally in a single module.
 */
export const formatDate = (dateString: string | undefined, defaultFormat = 'HH:mm dd/MM/yyyy'): string => {
  if (!dateString) return '';
  const dataObj = new Date(dateString);
  return isValid(dataObj) ? format(dataObj, defaultFormat) : '';
};

export const determineDateTime = (date: string): string => {
  if (isSameDay(new Date(), new Date(date))) return formatDate(date, 'HH:mm');

  return formatDate(date, 'dd/MM/yyyy');
};

export const getCurrencySymbol = (currency: string): string => {
  switch (currency) {
    case 'USD':
      return '$';
    case 'JPY':
      return '¥';
    case 'GBP':
      return '£';
    case 'EUR':
      return '€';
    default:
      return '$';
  }
};

export const daysDiff = (pastDate: string, futureDate: string): number =>
  differenceInDays(new Date(futureDate), new Date(pastDate));

export const getContractStatus = (status: string): { status: string; statusText: string } => {
  const position = ContractSteps.indexOf(status);
  if (!status || position < 0) {
    return {
      status: 'empty',
      statusText: 'Not Created',
    };
  }
  const isProgress = position < ContractSteps.length - 2;
  return {
    status: isProgress ? 'progress' : 'success',
    statusText: isProgress ? 'In Progress' : 'Finalized',
  };
};

export const truncateText = (text: string, limit = 50): string => {
  if (!text) return '';

  const ellipsis = text.length < limit ? '' : '...';

  return `${text.slice(0, limit)}${ellipsis}`;
};

export const parseJson = (jsonString: string | null | undefined, fallbackValue: unknown): any => {
  try {
    if (!jsonString) return fallbackValue;

    return JSON.parse(jsonString);
  } catch {
    return fallbackValue;
  }
};

export const formatMessageDate = (dateString: string) => {
  const date = new Date(dateString);

  if (isToday(date)) return 'Today';
  if (isYesterday(date)) return 'Yesterday';

  return formatDate(dateString, 'dd/MM/yyyy');
};

export const getUserSearchFilter = (query: string) => {
  const filter: Record<string, any> = {};

  filter.or = [{ searchName: { contains: query } }, { email: { contains: query } }];
  return filter;
};

export const setCredentialsInCache = (user: BasicUser) => {
  const credentials = parseJson(localStorage.getItem('credentials'), {});
  localStorage.setItem('credentials', JSON.stringify({ ...credentials, ...user }));
};

export const getCredentialsFromCache = () => parseJson(localStorage.getItem('credentials'), {});

export const flattenRoutes = (routesList: RouteInterface[]) =>
  routesList.reduce((acc: RouteInterface[], current) => {
    if (!current.routes) {
      return [...acc, current];
    }

    const { routes, ...rest } = current;
    return [...acc, rest, ...routes];
  }, []);

export const filterNavItems = (routes: RouteInterface[], pathname: string, permissions: string[]) => {
  const parentPath = pathname.split('/').filter(Boolean)[0] || '';
  if (!parentPath) return [];

  return routes.filter(
    (route) =>
      route.navbar &&
      route.path.includes(parentPath) &&
      (!route.permissions || permissions.some((permission) => route.permissions?.includes(permission)))
  );
};

export const bytesToSize = (bytes: number) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  if (i === 0) return `${bytes} ${sizes[i]})`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

export const getFormatedNumber = (
  amount: number | string | undefined,
  currencyCode: Currency | string = 'USD'
): string => {
  const numberAmount = Number(amount);
  if (Number.isNaN(numberAmount)) {
    return `${getCurrencySymbol(currencyCode)}0`;
  }

  return numberAmount.toLocaleString('en-US', {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
  });
};

export const stripHtmlTags = (content: string) => {
  if (!content) return '';
  return content.replace(/(<([^>]+)>)/gi, '');
};

export const countOccurence = (content: string, key: string, isHtmlMarkup: boolean = false) => {
  if (!content || !key) return 0;
  const contentText = isHtmlMarkup ? stripHtmlTags(content) : content;

  return (contentText.match(new RegExp(key, 'g')) || []).length;
};

export const getTreeFromFlatData = (documentSegments: DocumentSegment[], id: string | null = null): DocumentSegment[] =>
  documentSegments
    .filter((documentSegment) => documentSegment.parentId === id)
    .map((documentSegment) => {
      const childrens = getTreeFromFlatData(documentSegments, documentSegment.id);
      return {
        ...documentSegment,
        children: childrens.sort((a, b) => a.order - b.order),
      };
    });

export const flattenTree = (tree: DocumentSegment[]): DocumentSegment[] =>
  tree.reduce((acc: DocumentSegment[], node): DocumentSegment[] => {
    const { children, ...newNode } = node;
    if (node.children && node.children.length > 0) return [...acc, newNode, ...flattenTree(node.children)];
    return [...acc, newNode];
  }, []);

export const getDeleteParentSegmentUpdates = (
  documentSegments: DocumentSegment[],
  selectedSegmentId: string
): DeleteSegmentImpacts => {
  const selectedSegment = documentSegments.find((segment) => segment.id === selectedSegmentId);
  const childrenSegments = documentSegments.filter((segment) => segment.parentId === selectedSegmentId);

  if (!selectedSegment) {
    return {
      impactedSegments: [],
      updatedSegments: [],
    };
  }

  const impactedSegments = [
    {
      segmentId: selectedSegment.id,
      parentId: selectedSegment.parentId,
      isDeleted: true,
    },
    ...childrenSegments.map((segment) => ({
      segmentId: segment.id,
      parentId: selectedSegment.parentId,
      isDeleted: false,
    })),
  ];

  const updatedSegments = documentSegments
    .map((segment) => {
      if (segment.id !== selectedSegmentId) {
        const found = childrenSegments.find((s) => s.id === segment.id);
        return found
          ? {
              ...segment,
              parentId: selectedSegment.parentId,
            }
          : segment;
      }
      return null;
    })
    .filter(Boolean) as DocumentSegment[];

  return {
    impactedSegments,
    updatedSegments,
  };
};

export const isBondifier = (email: string) => /@bondify.com$/.test(email);

export const separateTags = (tags: string) =>
  tags
    ?.split(',')
    .map((str) => str.trim())
    .filter(Boolean)
    .sort((a, b) => a.localeCompare(b));
