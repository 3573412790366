import ReactDOM from 'react-dom';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { Amplify } from 'aws-amplify';
import materialTheme from 'utils/materialTheme';
import App from './App';
import config from './aws-config';
import { GlobalStoreProvider } from './store/store';
import 'typeface-muli';
import 'typeface-open-sans';

Amplify.configure(config);
window.Object.freeze = (obj: any) => obj;

ReactDOM.render(
  <GlobalStoreProvider>
    <ThemeProvider theme={materialTheme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </GlobalStoreProvider>,
  document.getElementById('root')
);
