import { lazy } from 'react';

const Login = lazy(() => import('scenes/Auth/Login/Login'));
const Authentication = lazy(() => import('scenes/Auth/Auth'));
const ForgotPassword = lazy(
  () => import('scenes/Auth/ForgotPassword/ForgotPassword')
);
const MandatoryPasswordChange = lazy(
  () => import('scenes/Auth/MandatoryPasswordChange/MandatoryPasswordChange')
);

const publicRoutes = [
  {
    name: 'login',
    path: '/login',
    component: Login,
    navbar: false,
    roles: [],
    permissions: [],
  },
  {
    name: 'authentication',
    path: '/signup',
    component: Authentication,
    navbar: false,
    roles: [],
    permissions: [],
  },
  {
    name: 'forgotPassword',
    path: '/forgot-password',
    component: ForgotPassword,
    navbar: false,
    roles: [],
    permissions: [],
  },
  {
    name: 'mandatoryPasswordChange',
    path: '/change-password',
    component: MandatoryPasswordChange,
    navbar: false,
    roles: [],
    permissions: [],
  },
];

export default publicRoutes;
