import { formatDate } from 'utils/helpers';

export const flattenResponse = (
  response: UserConversation[],
  userId: string,
  activeChatId?: string
): any => {
  let counter = 0;
  const result = response
    .map(
      ({
        conversation,
        removedAt,
        id: userConversationId,
        lastseen,
      }: UserConversation) => {
        const filteredMessages = conversation.messages.items.filter(
          (message: Message) =>
            !removedAt || +new Date(message.createdAt) > +new Date(removedAt)
        );

        if (
          (conversation.messages.items.length === 0 ||
            filteredMessages.length === 0) &&
          removedAt
        ) {
          return null;
        }

        const unreadMessages = filteredMessages.filter(
          (message: Message) =>
            (!lastseen || +new Date(message.createdAt) > +new Date(lastseen)) &&
            message.author.id !== userId
        );

        if (unreadMessages.length > 0 && conversation.id !== activeChatId) {
          counter += 1;
        }

        return {
          id: conversation.id,
          userConversationId,
          messages: filteredMessages,
          participants: conversation.participants.items.map(
            (item: { user: BasicUser }) => item.user
          ),
          lastseen,
          transaction: conversation.transaction,
          unreadCount: unreadMessages.length,
        };
      }
    )
    .filter(Boolean);

  return { unreadConversationsCount: counter, result };
};

export const mapConversations = (
  conversations: UserConversation[],
  userId: string,
  activeChatId: string
): { unreadConversationsCount: number; result: ChatType[] } =>
  flattenResponse(conversations, userId, activeChatId);

export const filterUsers = (
  usersList: BasicUser[],
  searchInputContent: string,
  currentUserId: string
): BasicUser[] => {
  if (!usersList || !currentUserId) return [];

  const lowerCaseInput = (searchInputContent || '').toLowerCase();

  return usersList.filter(
    ({ firstname, lastname, id }: BasicUser) =>
      id !== currentUserId &&
      `${firstname} ${lastname}`.toLowerCase().includes(lowerCaseInput)
  );
};

export const mapUsers = (
  data: any,
  userId: string,
  searchInput: string
): BasicUser[] => {
  if (searchInput === '') return [];
  return filterUsers(data?.getCompany.users?.items || [], searchInput, userId);
};

export const mapOwnersList = (data: Transaction[]): Transaction[] =>
  data
    .map((item: Transaction) => item.transactions)
    .sort(
      (prev: Transaction, next: Transaction) =>
        Date.parse(next.createdAt) - Date.parse(prev.createdAt)
    );

export const findParticipatingUsersById = (
  id: string,
  list: Transaction[]
): BasicUser[] => {
  const found = list.find((item: Transaction) => item.id === id);
  const items = found?.participatingUsers.items || [];
  return items.map((item) => item.users);
};

export const filterChats = (
  searchInputValue: string,
  currentUserId: string,
  chatList: ChatType[]
): ChatType[] => {
  if (!searchInputValue) return chatList;

  const lowerCaseInput = searchInputValue.toLowerCase();

  return chatList.filter(({ participants }: ChatType) => {
    const found = participants.find(
      ({ id }: BasicUser) => id !== currentUserId
    );
    if (!found) return false;

    const { firstname, lastname } = found;
    return `${firstname} ${lastname}`.toLowerCase().includes(lowerCaseInput);
  });
};

export const filterMessages = (
  searchInputContent: string,
  groupedMessages: Record<string, Message[]>
): Record<string, Message[]> => {
  if (!searchInputContent) return groupedMessages;

  const lowerCaseInput = searchInputContent.toLowerCase();

  return Object.keys(groupedMessages).reduce(
    (acc: Record<string, Message[]>, key: string) => {
      const currentValue = groupedMessages[key];
      const filtered = currentValue.filter(({ message }) =>
        message.toLowerCase().includes(lowerCaseInput)
      );

      if (filtered.length > 0) {
        return {
          ...acc,
          [key]: filtered,
        };
      }
      return acc;
    },
    {}
  );
};

export const getTransactionOwnerDetails = (
  ownersList: Transaction[],
  newTransactionId: string
) => {
  const transaction = ownersList?.find((item) => item.id === newTransactionId);

  return transaction
    ? {
        avatar: transaction.owner.avatar?.key,
        name: transaction.owner.companyName,
        label: `${transaction.type} ${formatDate(
          transaction.maturityDate,
          'MM/yyyy'
        )}`,
      }
    : {
        avatar: '',
        name: '',
        label: '',
      };
};

export const filterTransactionOwnersList = (
  transactionsOwnerList: Transaction[],
  searchInputContent: string
) => {
  if (!searchInputContent) return transactionsOwnerList;

  const lowerCaseInput = searchInputContent.toLowerCase();

  return transactionsOwnerList.filter((transaction: Transaction) => {
    const ownerDetails = `
      ${transaction.owner.companyName}
      ${transaction.type} ${formatDate(
      transaction.maturityDate as string,
      'MM/yyyy'
    )}`;

    return ownerDetails.toLowerCase().includes(lowerCaseInput);
  });
};
