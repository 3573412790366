import {
  UPDATE_AUTH,
  UPDATE_USER_INFO_PERMISSIONS,
  UPDATE_USER_INFO_COMPANY,
  UPDATE_USER_INFO_EMAIL,
  UPDATE_USER_INFO_AVATAR_KEY,
  UPDATE_USER_INFO_PHONE,
  UPDATE_USER_INFO,
} from 'store/actionTypes/userActionTypes';
import { UserInfoState } from 'store/storeTypes';
import { userInfoInitialState } from 'store/initialState';
import { ActionTypes } from 'store/actionTypes/actionTypes';

const userReducer = (
  state: UserInfoState = userInfoInitialState,
  action: ActionTypes
): UserInfoState => {
  switch (action.type) {
    case UPDATE_USER_INFO_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      };
    case UPDATE_USER_INFO_COMPANY:
      return {
        ...state,
        company: action.payload,
      };
    case UPDATE_USER_INFO_EMAIL: {
      return {
        ...state,
        email: action.payload,
      };
    }
    case UPDATE_USER_INFO_AVATAR_KEY:
      return {
        ...state,
        avatar: {
          ...state.avatar,
          key: action.payload,
        },
      };
    case UPDATE_USER_INFO_PHONE:
      return {
        ...state,
        phone: action.payload,
      };
    case UPDATE_USER_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_AUTH:
      return {
        ...state,
        authenticated: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
