import { ActionTypes } from '../actionTypes/actionTypes';
import { AppState } from '../storeTypes';
import { AppInitialState } from '../initialState';
import {
  UPDATE_MENU_SELECTION,
  UPDATE_HEADER,
  SET_MAIN_DRAWER,
  SET_NOTIFICATION_DRAWER,
  SET_COLLABORATION_DRAWER,
} from '../actionTypes/appActionTypes';

const drawersReducer = (
  state: AppState = AppInitialState,
  action: ActionTypes
): AppState => {
  switch (action.type) {
    case SET_MAIN_DRAWER:
      return {
        ...state,
        drawers: {
          ...state.drawers,
          mainDrawer: !state.drawers.mainDrawer,
        },
      };
    case SET_NOTIFICATION_DRAWER:
      return {
        ...state,
        drawers: {
          ...state.drawers,
          notificationDrawer: !state.drawers.notificationDrawer,
          collaborationDrawerOpen: false,
        },
      };
    case SET_COLLABORATION_DRAWER:
      return {
        ...state,
        drawers: {
          ...state.drawers,
          notificationDrawer: false,
          collaborationDrawerOpen: !state.drawers.collaborationDrawerOpen,
        },
      };
    case UPDATE_HEADER: {
      const {
        title = state.header.title,
        backUrl = '',
        isFocus = false,
        isClose = false,
        withAvatar = false,
        onClose,
      } = action.payload;
      return {
        ...state,
        header: {
          ...state.header,
          title,
          backUrl,
          isFocus,
          isClose,
          withAvatar,
          onClose,
        },
        ...(isFocus && { drawers: { ...state.drawers, mainDrawer: false } }),
      };
    }
    case UPDATE_MENU_SELECTION: {
      return {
        ...state,
        selectedMenu: action.payload,
      };
    }

    default:
      return state;
  }
};

export default drawersReducer;
