import { FC, memo, ReactNode, useEffect, useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar as AvatarImage } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { BrokenImage } from '@material-ui/icons';
import Storage from 'utils/Storage';

interface MainProps {
  size: number;
  imgKey: string | undefined;
  placeholderIcon?: ReactNode;
  className?: string;
}

interface PlaceholderProps {
  size: number;
}

const useStyles = makeStyles(() => ({
  avatar: {
    width: ({ size }: PlaceholderProps) => `${size}px !important`,
    height: ({ size }: PlaceholderProps) => `${size}px !important`,
  },
  image: {
    width: '100%',
    height: 'auto',
  },
  hidden: {
    display: 'none',
  },
}));

const Avatar: FC<MainProps> = ({
  size,
  placeholderIcon,
  imgKey,
  className,
}) => {
  const { avatar, image, hidden } = useStyles({ size });

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [imgUrl, setImgUrl] = useState<string>('');

  const getUrl = useCallback(async () => {
    if (!imgKey) {
      setLoading(false);
      return;
    }
    const url = (await Storage.get(imgKey || '').catch(() => {
      setError(true);
      setLoading(false);
    })) as string;
    setImgUrl(url);
  }, [imgKey]);

  useEffect(() => {
    getUrl();
  }, [getUrl]);

  if (!imgKey && !loading) {
    return (
      <AvatarImage
        className={`${avatar} ${className || ''}`}
        title="placeholder-image"
      >
        {placeholderIcon}
      </AvatarImage>
    );
  }

  if (error) {
    return (
      <AvatarImage
        className={`${avatar} ${className || ''}`}
        title="broken-image"
      >
        <BrokenImage />
      </AvatarImage>
    );
  }

  const handleImageLoaded = () => {
    setLoading(false);
  };

  const handleImageLoadError = () => {
    setError(true);
  };

  return (
    <>
      {loading && (
        <Skeleton
          animation="wave"
          variant="circle"
          width={size}
          height={size}
          title="loading-skeleton"
          className={`${className || ''}`}
        />
      )}
      {imgUrl && (
        <AvatarImage
          className={`${avatar} ${className || ''} ${loading ? hidden : ''}`}
        >
          <img
            src={imgUrl}
            alt="avatar"
            className={image}
            onLoad={handleImageLoaded}
            onError={handleImageLoadError}
          />
        </AvatarImage>
      )}
    </>
  );
};

export default memo(Avatar);
