import { ActionTypes } from 'store/actionTypes/actionTypes';
import {
  UPDATE_NEW_TRANSACTION_ID,
  SET_ACTIVE_CHAT,
  SET_ACTIVE_USER_CONVERSATION_ID,
  SET_UNREAD_CONVERSATION_COUNT,
  SET_CHAT_PANEL,
  SET_SELECTED_CHAT_TAB,
  START_INSTANT_TRANSCATION_CHAT,
} from 'store/actionTypes/conversationActionTypes';
import { conversationInitialState } from 'store/initialState';
import { ConversationState } from 'store/storeTypes';

const conversationReducer = (
  state: ConversationState = conversationInitialState,
  action: ActionTypes
): ConversationState => {
  switch (action.type) {
    case UPDATE_NEW_TRANSACTION_ID:
      return {
        ...state,
        newTransactionId: action.payload,
      };
    case SET_ACTIVE_CHAT:
      return {
        ...state,
        activeChatId: action.payload,
      };
    case SET_ACTIVE_USER_CONVERSATION_ID:
      return {
        ...state,
        activeUserConversationId: action.payload,
      };
    case SET_UNREAD_CONVERSATION_COUNT:
      return {
        ...state,
        unreadConversationsCount: action.payload,
      };
    case SET_CHAT_PANEL:
      return {
        ...state,
        collaborationPanel: action.payload,
      };
    case SET_SELECTED_CHAT_TAB:
      return {
        ...state,
        selectedChatTab: action.payload,
      };
    case START_INSTANT_TRANSCATION_CHAT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default conversationReducer;
